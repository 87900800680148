import React, { useContext, useRef } from 'react';
import { useNavigate } from 'react-router';
import Addresses from '../../components/Addresses';
import DocketSwitch from '../../containers/OrderCreate/DocketSwitch';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { checkFilenameLength, cloudServices, dateConverter, parseFileSize, parseToOptions } from '../../helpers';
import Canvas from '../Canvas/Canvas';
import CheckboxInput from '../Inputs/CheckboxInput';
import RadioInput from '../Inputs/RadioInput';
import SelectInput from '../Inputs/SelectInput';
import TextareaInput from '../Inputs/TextareaInput';
import TextInput from '../Inputs/TextInput';
import Loading from '../Loading/Loading';
import FlexModal from '../Modals/FlexModal';
import ProcessingModal from '../Modals/ProcessingModal';
import SearchInput from '../Inputs/SearchInput';
import Button from '../Button';
import FormStep from './Components/FormStep';
import FileInput from '../Inputs/FileInput';
import { FileIcon, PlusIcon, SearchIcon, TrashIcon } from '../SVGIcons/SVGIcons';
import DateInput from '../Inputs/DateInput';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import Upload from '../Upload';
import { useModalsGlobal } from '../../hooks/useModalsGlobal';
import PackageChoiceItem from '../ModalListing/PackageChoiceItem';

export default function OrderForm(props) {
	const {
		handleCustomFormSelectData,
		handleFormSelectData,
		handleCustomFormData,
		handleNestedFormData,
		onBehalfOfAddresses,
		dentallyIntegration,
		handleAddressInput,
		handleDocketSelect,
		changeReqOrderUuid,
		handleCustomFiles,
		handlePatientData,
		handleCanvasFile,
		setChosenAddress,
		addSuppAsTrusted,
		handleOrderFiles,
		searchSuppliers,
		customFormFiles,
		handleFormData,
		selectedDocket,
		customFormData,
		chooseSupplier,
		changeReqUuid,
		chosenAddress,
		orderExtFiles,
		deleteExtFile,
		handleSearch,
		customErrors,
		toggleSearch,
		setSuppliers,
		parentOrder,
		handleModal,
		handlePackages,
		replaceFile,
		setFormOpen,
		handleNewFiles,
		orderFiles,
		searchOpen,
		removeFile,
		deleteItem,
		parentUuid,
		setAddress,
		orderItems,
		deleteFile,
		addExtFile,
		modalOpen,
		suppliers,
		patients,
		formData,
		packages,
		formOpen,
		addItem,
		addFile,
		focused,
		address,
		loading,
		dockets,
		loaded,
		order,
	} = props?.formProps || {};

	const { slideWidth, steps, resetSlide, skip } = props || {};

	const pageStart = useRef(null);
	const pageEnd = useRef(null);

	let { teamState } = useContext(CurrentTeamContext);
	let { userState } = useContext(CurrentUserContext);

	const { showModal } = useModalsGlobal();

	let navigate = useNavigate();

	const EDINBURGH_TEAMUUID = 'b0f94f24-b81c-4ffd-89f0-47a61df1f875';

	const openPackageChoiceList = () =>
		showModal('packageChoiceList', {
			callback: (e) =>
				handleFormData(
					{
						name: e?.name,
						value: { packageUuid: e?.value },
					},
					'package'
				),
			packages: packages ? Object.values(packages)?.reverse() : [],
			openPackageNewModal: () =>
				handleModal('packageNewModal', {
					callback: handlePackages,
					openPackageChoiceList,
				}),
			hideBackground: true,
		});

	const IDENTIFIER_TEXT = {
		COMPANY: `You are logged in as ${userState?.currUser?.name} and are representing ${teamState?.currentTeam?.teamInfo?.teamName}`,
		PRIVATE: `You are logged in as ${userState?.currUser?.name} and are ordering from your personal account`,
	};
	const maxSize = 2000 * 1000 * 1000;

	function scrollToBottom() {
		setTimeout(function () {
			if (pageEnd) {
				pageEnd.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
			}
		}, 1);
	}

	return (
		<>
			{modalOpen['imgCanvasModal']?.open && (
				<FlexModal
					setShow={handleModal}
					modalName={'imgCanvasModal'}
					zIndex={99}
					closeBtn={true}
					callback={() => {
						replaceFile();
						handleModal('imgCanvasModal', { open: false, file: null, index: null });
					}}
					body={
						<Canvas
							cWidth={500}
							cHeight={700}
							img={modalOpen['imgCanvasModal']?.file}
							handleFile={handleCanvasFile}
						/>
					}
				/>
			)}
			{!loading ? (
				<>
					<div className='pageStart' ref={pageStart}></div>
					{!changeReqUuid && (
						<FormStep
							closed={steps?.count !== steps?.stepRefs?.chooseSupplier?.stepNumber}
							style={{
								minWidth: `${slideWidth}px`,
							}}>
							<form id={steps?.stepRefs?.chooseSupplier?.stepNumber}>
								{chooseSupplier && (
									<>
										{loaded ? (
											<div className='formSection'>
												<div className='sectionRow'>
													<div className='innerCol'>
														<h4>Choose your supplier</h4>
													</div>
													<div className='innerCol'>
														{suppliers?.length > 0 && (
															<SelectInput
																options={suppliers}
																callback={handleFormSelectData}
																value={formData.chosenSupplier}
																name={'chosenSupplier'}
																customError={customErrors['chosenSupplier']}
																required
															/>
														)}
														{addSuppAsTrusted && (
															<CheckboxInput
																text={`Save ${addSuppAsTrusted?.name} as a trusted supplier`}
																name={'newTrusted'}
																checked={formData.newTrusted}
																callback={handleFormData}
																customError={customErrors?.newTrusted}
															/>
														)}
														{!searchOpen && (
															<div className='searchCont'>
																<Button
																	text='Search supplier'
																	style='secondary'
																	iconLeft={<SearchIcon />}
																	size='md'
																	type='button'
																	minWidth={164}
																	onClick={() => {
																		toggleSearch();
																	}}
																/>
															</div>
														)}
														{searchOpen && (
															<SearchInput
																handleOption={(e) => {
																	handleFormSelectData(e, 'chosenSupplier');
																	toggleSearch();
																	setSuppliers((suppliers) => [...suppliers, e]);
																}}
																callback={handleSearch}
																type='suppliers'
																options={parseToOptions(searchSuppliers, 'suppliers')}
																name='searchSupplier'
															/>
														)}
													</div>
												</div>
											</div>
										) : (
											<Loading type='facebook' />
										)}
									</>
								)}
								{dentallyIntegration?.token &&
									formData?.chosenSupplier &&
									teamState?.currentTeam?.teamUuid === EDINBURGH_TEAMUUID && (
										<div className='formSection'>
											<div className='sectionRow'>
												<div className='innerCol'>
													<h4>Search Dentally Patient</h4>
												</div>
												<div className='innerCol'>
													<SearchInput
														dentallyToken={dentallyIntegration?.token}
														handleOption={handlePatientData}
														callback={handleSearch}
														type='dentallyPatients'
														options={patients}
														name='searchDentallyPatient'
													/>
													{formData?.patient && (
														<div className='patientCont'>
															<div className='col'>
																<h5 className='label'>Name</h5>
																<p className='patientData'>
																	{formData?.patient?.first_name
																		? `${formData?.patient?.first_name}`
																		: 'N/A'}
																</p>
															</div>
															<div className='col'>
																<h5 className='label'>ID</h5>
																<p className='patientData'>
																	{formData?.patient?.id
																		? `${formData?.patient?.id}`
																		: 'N/A'}
																</p>
															</div>
															<div className='col'>
																<h5 className='label'>DOB</h5>
																<p className='patientData'>
																	{formData?.patient?.date_of_birth
																		? `${formData?.patient?.date_of_birth}`
																		: 'N/A'}
																</p>
															</div>
														</div>
													)}
												</div>
											</div>
										</div>
									)}
								<div className='formSection'>
									<div className='sectionRow'>
										<div className='innerCol'>
											<h4>Hint</h4>
										</div>
										<div className='innerCol'>
											<TextInput
												name={'buyerHint'}
												customError={customErrors?.buyerHint}
												defaultValue={formData.buyerHint}
												tooltip={
													"Add your Patient name as a hint to identify your prescription easily. Example 'Julie Smith lower bite block'."
												}
												callback={handleFormData}
												required
											/>
										</div>
									</div>
								</div>
								<div className='formSection'>
									<div className='sectionRow'>
										<div className='innerCol'>
											<h4>Who is ordering?</h4>
										</div>
										<div className='innerCol'>
											<p className='identifierText'>
												{teamState?.isTeamOfOne
													? IDENTIFIER_TEXT.PRIVATE
													: IDENTIFIER_TEXT.COMPANY}
											</p>
											{teamState?.userPermissions?.authorizedOnBehalf === false && (
												<CheckboxInput
													text={'Authorization'}
													checkboxText={`I hereby verify that I am authorized to purchase on behalf of${' '}
									${teamState?.currentTeam?.teamInfo?.teamName}`}
													name={'authorization'}
													checked={formData.authorization}
													callback={handleFormData}
													required
													customError={customErrors['authorization']}
												/>
											)}
											{teamState.currentTeam.teamUuid === formData.chosenSupplier?.value && (
												<>
													{teamState.currentTeam?.trustedBy &&
														Object.keys(teamState.currentTeam?.trustedBy)?.length > 0 && (
															<SelectInput
																text='Buyer team'
																options={parseToOptions(
																	[
																		...Object.values(
																			teamState.currentTeam.trustedBy
																		)?.filter(
																			(team) =>
																				team?.preferences?.allowOnBehalf ===
																				true
																		),
																		{
																			teamUuid: teamState.currentTeam.teamUuid,
																			members: teamState.currentTeam.members,
																			teamName:
																				teamState.currentTeam.teamInfo.teamName,
																		},
																	],
																	'trustedTeams'
																)}
																callback={handleFormSelectData}
																value={formData.onBehalfOfBuyerTeam}
																name={'onBehalfOfBuyerTeam'}
																customError={customErrors?.onBehalfOfBuyerTeam}
																tooltip='As you are purchasing towards your own team, you will need to specify which team you are purchasing on behalf of'
															/>
														)}
												</>
											)}
											{teamState?.userPermissions?.authorizedOnBehalf ||
											formData.onBehalfOfBuyerTeam ? (
												<>
													{formData?.onBehalfOfBuyerTeam && (
														<SelectInput
															options={
																formData?.onBehalfOfBuyerTeam
																	? [
																			...parseToOptions(
																				teamState.currentTeam?.trustedBy[
																					formData?.onBehalfOfBuyerTeam?.value
																				]?.members?.filter(
																					(member) =>
																						member.permissions.isBuyer ===
																						true
																				),
																				'members'
																			),
																	  ]
																	: []
															}
															callback={handleFormSelectData}
															text={'On behalf of'}
															value={formData.behalfOf || false}
															placeholder={false}
															name={'behalfOf'}
															customError={customErrors?.behalfOf}
															reverse
														/>
													)}
													{!formData?.onBehalfOfBuyerTeam && (
														<SelectInput
															options={[
																{ name: 'No', value: false },
																...parseToOptions(
																	teamState?.currentTeam?.members?.filter(
																		(member) => member.firstname && member.lastname
																	),
																	'members'
																),
															]}
															callback={handleFormSelectData}
															text={'Are you acting on behalf of someone?'}
															value={formData.behalfOf || false}
															placeholder={false}
															name={'behalfOf'}
															customError={customErrors?.behalfOf}
															reverse
														/>
													)}
												</>
											) : (
												<></>
											)}
										</div>
									</div>
								</div>
							</form>
						</FormStep>
					)}
					{dockets?.length > 0 && (
						<FormStep
							alignCenter
							closed={steps?.count !== steps?.stepRefs?.customForm?.stepNumber}
							style={{
								minWidth: `${slideWidth}px`,
							}}>
							{dockets.length > 1 && (
								<div className='formSection'>
									<div className='sectionRow'>
										<div className='innerCol'>
											<h4>Choose order form</h4>
										</div>
										<div className='innerCol centerInput'>
											<SelectInput
												options={parseToOptions(
													dockets.filter((docket) => docket.docketType === 1),
													'dockets'
												)}
												callback={handleDocketSelect}
												value={dockets[selectedDocket]?.docketUuid}
												name={'orderType'}
												center
											/>
										</div>
									</div>
								</div>
							)}
							<div className='mainFormCont fixed'>
								<form id={steps?.stepRefs?.customForm?.stepNumber}>
									<div className='formSection center'>
										{dockets[selectedDocket]?.fields?.length > 0 ? (
											dockets[selectedDocket]?.fields.map((field, key) => {
												return (
													<DocketSwitch
														key={key}
														field={field}
														handleFormSelectData={handleCustomFormSelectData}
														handleFormData={handleCustomFormData}
														formData={customFormData}
														focused={focused}
														formFiles={customFormFiles}
														handleFiles={handleCustomFiles}
														parentOrder={parentOrder}
														autofill={parentUuid}
														customErrors={customErrors}
														dentallyData={formData?.patient}
													/>
												);
											})
										) : (
											<h4>Form is empty. Please contact the seller regarding this issue.</h4>
										)}
									</div>
								</form>
							</div>
						</FormStep>
					)}
					<FormStep
						alignCenter
						closed={steps?.count !== steps?.stepRefs?.accompaniments?.stepNumber}
						style={{
							minWidth: `${slideWidth}px`,
						}}>
						<form id={'accompanimentsAdd'}>
							<div className='formSection'>
								<div className='sectionRow'>
									<div className='innerCol'>
										<h4>Accompaniments</h4>
										<p className='fieldDesc'>
											Are you sending physical items to your supplier? If so, fill in the form. If
											not, then{' '}
											<span className='spanLinkText' onClick={skip}>
												skip
											</span>{' '}
											this screen
										</p>
									</div>
									{formOpen?.accompaniments === true && (
										<div className='innerCol'>
											<TextInput
												text={'Name of the item'}
												name={'itemDesc'}
												callback={handleFormData}
												value={formData.itemDesc}
												customError={customErrors['itemDesc']}
												controlled
												required
											/>
											<TextareaInput
												text={'Note (optional)'}
												name={'note'}
												value={formData.note}
												callback={handleFormData}
												placeholder={'Note for recipient.'}
												controlled
												short
											/>
											{/* IF NEED TO UPDATE LIVE, PUT THIS IN */}
											{/* <SelectInput
												options={parseToOptions(
													packages && Object.values(packages),
													'packages'
												).reverse()}
												callback={(e) => {
													handleFormData(
														{ name: e?.name, value: { packageUuid: e?.value } },
														'package'
													);
												}}
												value={formData?.package?.value?.packageUuid}
												text={'Add to package'}
												name={'package'}
												customError={customErrors['package']}
												missingOptions={{
													message: 'Add package',
													callback: () => {
														handleModal('packageNewModal', true);
													},
												}}
												required
											/>
											<div className='btnCont'>
												<Button
													text='Add package'
													style='secondary'
													size='md'
													type='button'
													minWidth={164}
													onClick={() => {
														handleModal('packageNewModal', true);
													}}
												/>
											</div> */}
											{/* IF NEED TO UPDATE LIVE, PUT THIS IN */}
											{!formData?.package ? (
												<div className='btnCont'>
													<Button
														text='Choose package'
														style='secondary'
														size='md'
														type='button'
														minWidth={164}
														onClick={openPackageChoiceList}
													/>
												</div>
											) : (
												<>
													<PackageChoiceItem
														pack={packages[formData?.package?.value?.packageUuid]}
													/>
													<div className='btnCont'>
														<Button
															text='Change package'
															style='secondary'
															size='md'
															type='button'
															minWidth={164}
															onClick={openPackageChoiceList}
														/>
													</div>
												</>
											)}
											<div className='btnCont'>
												<Button
													text='Add accompaniment'
													style='primary'
													size='md'
													type='button'
													disabled={!formData.package?.value?.packageUuid}
													minWidth={164}
													onClick={async () => {
														let wasAdded = await addItem();
														wasAdded === true &&
															setFormOpen((formOpen) => ({
																...formOpen,
																accompaniments: false,
															}));
													}}
												/>
											</div>
										</div>
									)}
									{orderItems &&
										Object.keys(orderItems).length > 0 &&
										formOpen?.accompaniments === false && (
											<Button
												text='Add another'
												style='primary'
												size='md'
												type='button'
												minWidth={164}
												onClick={() => {
													setFormOpen((formOpen) => ({
														...formOpen,
														accompaniments: true,
													}));
												}}
											/>
										)}
								</div>
							</div>
						</form>
						{orderItems && Object.keys(orderItems).length > 0 && (
							<div className='formSection'>
								<div className='sectionRow'>
									<div className='innerCol'>
										<h4>Added accompaniments</h4>
										<p className='fieldDesc'>These will be added when order is finished</p>
									</div>
									<div className='wideInnerCol'>
										<div className='orderItemList'>
											{orderItems &&
												Object.keys(orderItems).length > 0 &&
												Object.values(orderItems).map((item, key) => {
													return (
														<div className='orderItem' key={key}>
															<h4>{item.itemDesc}</h4>
															{item.note ? <h4>{item.note}</h4> : <></>}
															{item.package?.courier?.companyName ? (
																<h4>{item.package?.courier?.companyName}</h4>
															) : (
																<></>
															)}
															<Button
																text='Remove'
																style='secondary'
																size='sm'
																type='button'
																minWidth={164}
																onClick={() => {
																	deleteItem(item.itemUuid);
																}}
															/>
														</div>
													);
												})}
										</div>
									</div>
								</div>
							</div>
						)}
					</FormStep>
					<FormStep
						alignCenter
						closed={steps?.count !== steps?.stepRefs?.files?.stepNumber}
						style={{
							minWidth: `${slideWidth}px`,
						}}>
						<form id={'filesAdd'}>
							<div className='formSection'>
								<div className='sectionRow'>
									<div className='innerCol'>
										<h4>Files</h4>
										<p className='fieldDesc'>
											Would you like to upload digital files to send to your supplier? If so, fill
											in the form. If not, then{' '}
											<span className='spanLinkText' onClick={skip}>
												skip
											</span>{' '}
											this screen
										</p>
									</div>
									{formOpen?.orderFiles === true && (
										<div className='innerCol'>
											{/* <TextInput
												text={'Display name'}
												name={'displayName'}
												callback={(e) => setFile((file) => ({ ...file, displayName: e }))}
												value={file?.displayName}
												customError={customErrors.displayName}
												controlled
											/>
											<TextInput
												text={'Caption'}
												name={'caption'}
												btnText={'Choose file'}
												callback={(e) => setFile((file) => ({ ...file, caption: e }))}
												value={file?.caption}
												customError={customErrors.caption}
												controlled
											/> */}
											{/* <FileInput
												name={'file'}
												btnText={'Choose file'}
												callback={(e) => setFile((file) => ({ ...file, file: e }))}
											/>

											{file?.file && (
												<>
													<div className='fileCont'>
														<FileIcon iconClass='placeholder' />
														<div className='fileRow'>
															<div>
																<h4 className='text'>
																	{checkFilenameLength(file?.file.name, 20)}
																</h4>
																<h4 className='text'>
																	{parseFileSize(file?.file.size)}
																</h4>
															</div>
															<TrashIcon
																iconClass='trashIcon'
																onClick={() => setFile({})}
															/>
														</div>
													</div>
													<div className='btnCont padding-0'>
														<Button
															text='Add file'
															style='primary'
															btnIconLeft={<PlusIcon />}
															size='md'
															type='button'
															minWidth={164}
															onClick={() => {
																addFile();
																setFile({});
																setFormOpen((formOpen) => ({
																	...formOpen,
																	orderFiles: false,
																}));
															}}
														/>
													</div>
												</>
											)} */}
											<div className='upload'>
												<Upload
													props={{
														maxFileSize: maxSize,
														files: orderFiles,
														handleNewFiles,
														removeFile,
													}}
													interactType='form'
												/>
											</div>
										</div>
									)}
									{orderFiles && orderFiles.length > 0 && formOpen?.orderFiles === false && (
										<Button
											text='Add another'
											style='primary'
											size='md'
											type='button'
											minWidth={164}
											onClick={() => {
												setFormOpen((formOpen) => ({
													...formOpen,
													orderFiles: true,
												}));
											}}
										/>
									)}
								</div>
							</div>
						</form>
						{orderFiles && orderFiles.length > 0 && (
							<div className='formSection'>
								<div className='sectionRow'>
									<div className='innerCol'>
										<h4>Added files</h4>
										<p className='fieldDesc'>These will be created when order is finished</p>
									</div>
									<div className='wideInnerCol'>
										<div className='orderItemList'>
											{orderFiles &&
												orderFiles.length > 0 &&
												orderFiles.map((file, key) => {
													if (file) {
														return (
															<div className='fileCont' key={key}>
																<FileIcon iconClass='placeholder' />
																<div className='fileRow'>
																	<div>
																		<h4 className='text'>
																			{checkFilenameLength(file?.name, 20)}
																		</h4>
																		<h4 className='text'>
																			{parseFileSize(file?.size)}
																		</h4>
																	</div>
																	<TrashIcon
																		iconClass='trashIcon'
																		onClick={() => removeFile(file?.fileUuid)}
																	/>
																</div>
															</div>
														);
													} else {
														return <React.Fragment key={key}></React.Fragment>;
													}
												})}
										</div>
									</div>
								</div>
							</div>
						)}
					</FormStep>
					<FormStep
						alignCenter
						closed={steps?.count !== steps?.stepRefs?.external?.stepNumber}
						style={{
							minWidth: `${slideWidth}px`,
						}}>
						<form id={'externalAdd'}>
							<div className='formSection'>
								<div className='sectionRow'>
									<div className='innerCol'>
										<h4>External attachments</h4>
										<p className='fieldDesc'>
											Have you sent files associated with this order via a third party service? If
											so, fill in the form. If not, then{' '}
											<span className='spanLinkText' onClick={skip}>
												skip
											</span>{' '}
											this screen
										</p>
									</div>
									{formOpen?.orderExtFiles === true && (
										<div className='innerCol'>
											<SelectInput
												options={parseToOptions(Object.values(cloudServices), 'cloudServices')}
												callback={(value, name) => {
													handleNestedFormData(
														{ value: cloudServices[value?.value], name },
														'extFile'
													);
												}}
												text={'Service'}
												name={'service'}
												value={formData?.extFile?.service?.serviceUuid}
												customError={customErrors.service}
												required
											/>
											{formData?.extFile?.service &&
												cloudServices[formData?.extFile?.service?.name].fields.map(
													(field, key) => {
														return (
															<div key={key}>
																{field.type === 'text' && (
																	<TextInput
																		text={field.label}
																		name={field.name}
																		callback={(value, name) => {
																			handleNestedFormData(
																				{ value, name },
																				'extFile'
																			);
																		}}
																		value={formData.extFile[field.name]}
																		customError={customErrors[field.name]}
																		controlled
																	/>
																)}
																{field.type === 'date' && (
																	<DateInput
																		text={field.label}
																		name={field.name}
																		callback={(value, name) => {
																			handleNestedFormData(
																				{ value: value.toString(), name },
																				'extFile'
																			);
																		}}
																		defaultValue={formData.extFile[field.name]}
																		focused={false}
																		dateFormat='P'
																	/>
																)}
															</div>
														);
													}
												)}
											<div className='btnCont'>
												<Button
													text='Submit'
													style='primary'
													size='md'
													type='button'
													minWidth={164}
													onClick={() => {
														addExtFile();
														setFormOpen((formOpen) => ({
															...formOpen,
															orderExtFiles: false,
														}));
													}}
												/>
											</div>
										</div>
									)}
									{orderExtFiles &&
										Object.keys(orderExtFiles).length > 0 &&
										formOpen?.orderExtFiles === false && (
											<Button
												text='Add another'
												style='primary'
												size='md'
												type='button'
												minWidth={164}
												onClick={() => {
													setFormOpen((formOpen) => ({
														...formOpen,
														orderExtFiles: true,
													}));
												}}
											/>
										)}
								</div>
							</div>
						</form>
						{orderExtFiles && Object.keys(orderExtFiles).length > 0 && (
							<div className='formSection'>
								<div className='sectionRow'>
									<div className='innerCol'>
										<h4>Added external attachments</h4>
										<p className='fieldDesc'>These will be created when order is finished</p>
									</div>
									<div className='wideInnerCol'>
										<div className='orderItemList'>
											{orderExtFiles &&
												Object.keys(orderExtFiles).length > 0 &&
												Object.values(orderExtFiles).map((file, key) => {
													return (
														<div className='orderItem' key={key}>
															{file.service?.fields?.map((field, key) => {
																if (file[field.name]) {
																	return (
																		<h4 key={key}>
																			{dateConverter(file[field?.name])}
																		</h4>
																	);
																} else {
																	return <React.Fragment key={key}></React.Fragment>;
																}
															})}
															<h4>{file.caption}</h4>
															<Button
																text='Remove'
																style='secondary'
																size='sm'
																type='button'
																minWidth={164}
																onClick={() => {
																	deleteExtFile(file.extFileUuid);
																}}
															/>
														</div>
													);
												})}
										</div>
									</div>
								</div>
							</div>
						)}
					</FormStep>
					{!changeReqUuid ? (
						<FormStep
							alignCenter
							closed={steps?.count !== steps?.stepRefs?.submit?.stepNumber}
							style={{
								minWidth: `${slideWidth}px`,
							}}>
							<form id={steps?.stepRefs?.submit?.stepNumber}>
								<div className='formSection'>
									<div className='sectionRow'>
										<div className='innerCol'>
											<h3>Permissions</h3>
										</div>
										<div className='innerCol'>
											<RadioInput
												text={'Accept estimate automatically'}
												name={'acceptEstimate'}
												defaultValue={formData.acceptEstimate}
												callback={handleFormData}
												radios={[
													{ value: true, text: 'Yes' },
													{ value: false, text: 'No, authorization required' },
												]}
												required
												customError={customErrors['acceptEstimate']}
											/>
											<RadioInput
												text={
													'Will you automatically accept changes to pricing throughout the manufacturing process?'
												}
												name={'acceptPricing'}
												defaultValue={formData.acceptPricing}
												callback={handleFormData}
												radios={[
													{ value: true, text: 'Yes' },
													{ value: false, text: 'No, each pricing change needs approval' },
												]}
												required
												customError={customErrors['acceptPricing']}
											/>
										</div>
									</div>
								</div>
								{formData.supplier?.teamInfo?.collectionAddress ? (
									<div className='formSection'>
										<div className='sectionRow'>
											<div className='innerCol'>
												<h3>Delivery/Collection</h3>
												<p className='fieldDesc'>
													Choose a delivery address or choose to collect
												</p>
											</div>
											<div className='innerCol'>
												<RadioInput
													text={
														'Would you like to collect your item or have it dispatched to you?'
													}
													name={'collect'}
													defaultValue={formData.collect}
													callback={handleFormData}
													radios={[
														{ value: true, text: 'Collection' },
														{ value: false, text: 'Delivery' },
													]}
													required
													customError={customErrors?.collect}
												/>

												{formData.collect === false && (
													<Addresses
														address={address}
														setAddress={setAddress}
														addresses={
															formData?.onBehalfOfBuyerTeam && onBehalfOfAddresses
																? onBehalfOfAddresses?.reduce(
																		(arr, value) => ({
																			...arr,
																			[value?.addressUuid]: value,
																		}),
																		{}
																  )
																: teamState?.currentTeam?.teamInfo?.addresses?.reduce(
																		(arr, value) => ({
																			...arr,
																			[value?.addressUuid]: value,
																		}),
																		{}
																  )
														}
														chosenAddress={chosenAddress}
														setChosenAddress={setChosenAddress}
														handleAddressInput={handleAddressInput}
														scrollToBottom={scrollToBottom}
														layoutType={'addressPicker'}
													/>
												)}
												{formData.collect === true && (
													<>
														<h4>
															You will be notified when your order is ready to be picked
															up at the address below
														</h4>
														<div className='formRowTop'>
															<div className='addressDisplay'>
																{`${
																	formData.supplier?.teamInfo?.collectionAddress?.name
																		? `${formData.supplier?.teamInfo?.collectionAddress?.name}, `
																		: ''
																}${
																	formData.supplier?.teamInfo?.collectionAddress
																		?.addressLine1
																		? `${formData.supplier?.teamInfo?.collectionAddress?.addressLine1}, `
																		: ''
																} ${
																	formData.supplier?.teamInfo?.collectionAddress?.city
																		? `${formData.supplier?.teamInfo?.collectionAddress?.city}, `
																		: ''
																} ${
																	formData.supplier?.teamInfo?.collectionAddress
																		?.postalCode
																		? `${formData.supplier?.teamInfo?.collectionAddress?.postalCode},`
																		: ''
																} ${
																	formData.supplier?.teamInfo?.collectionAddress
																		?.country
																		? `${formData.supplier?.teamInfo?.collectionAddress?.country}`
																		: ''
																}`}
															</div>
														</div>
													</>
												)}
											</div>
										</div>
									</div>
								) : (
									<div className='formSection'>
										<div className='sectionRow'>
											<div className='innerCol'>
												<h3>Delivery</h3>
												<p className='fieldDesc'>Choose a delivery address</p>
											</div>
											<div className='innerCol'>
												<Addresses
													address={address}
													setAddress={setAddress}
													addresses={
														formData?.onBehalfOfBuyerTeam && onBehalfOfAddresses
															? onBehalfOfAddresses?.reduce(
																	(arr, value) => ({
																		...arr,
																		[value?.addressUuid]: value,
																	}),
																	{}
															  )
															: teamState?.currentTeam?.teamInfo?.addresses?.reduce(
																	(arr, value) => ({
																		...arr,
																		[value?.addressUuid]: value,
																	}),
																	{}
															  )
													}
													chosenAddress={chosenAddress}
													setChosenAddress={setChosenAddress}
													handleAddressInput={handleAddressInput}
													scrollToBottom={scrollToBottom}
													layoutType={'addressPicker'}
												/>
											</div>
										</div>
									</div>
								)}
							</form>
						</FormStep>
					) : (
						<FormStep
							alignCenter
							closed={steps?.count !== steps?.stepRefs?.submit?.stepNumber}
							style={{
								minWidth: `${slideWidth}px`,
							}}>
							<h3>Your change request is ready to submit</h3>
						</FormStep>
					)}
					<FormStep
						alignCenter
						closed={steps?.count !== steps?.stepRefs?.end?.stepNumber}
						style={{
							minWidth: `${slideWidth}px`,
							maxHeight: `${steps?.count !== steps?.stepRefs?.end?.stepNumber ? `0px` : '100%'}`,
							padding: `${
								steps?.count !== steps?.stepRefs?.end?.stepNumber ? `0px` : '40px 0px 0px 0px'
							}`,
						}}>
						{order?.orderUuid ? (
							<>
								<h2 style={{ textAlign: 'center' }} className='marginBottom-20'>
									{!changeReqUuid ? (
										<>
											{order?.orderProcessingStatus !== 0
												? 'Thank you for your order!'
												: 'The order was saved as a draft and can be sent to the seller later.'}
										</>
									) : (
										<>The change request was made successfully</>
									)}
								</h2>
								<div className='btnContCol marginBottom-20'>
									<Button
										text='Go to the Order'
										style='primary'
										size='sm'
										type='button'
										onClick={() => {
											navigate(
												`/orders/${changeReqOrderUuid || order.orderUuid}?orderRef=${
													order.orderRef
												}`
											);
										}}
									/>
									<Button
										text='Go to the Dashboard'
										style='secondary'
										size='sm'
										type='button'
										onClick={() => {
											navigate(`/`);
										}}
									/>
									{!changeReqUuid && (
										<Button
											text='Create another order'
											style='secondary'
											size='sm'
											type='button'
											onClick={() => {
												window.location.reload();
											}}
										/>
									)}
								</div>
								{orderItems?.length > 0 && (
									<>
										<div className='formSection marginTop-20'>
											<div className='sectionRow'>
												<div className='innerCol'>
													<h3>Send your accompaniments</h3>
													<p className='fieldDesc'>
														Print the labels, stick them to your accompiments and place them
														in your package.
													</p>
												</div>
												<div className='wideInnerCol'>
													<div className='orderItemList'>
														{orderItems?.map((item, key) => {
															return (
																<div className='orderItem' key={key}>
																	<h4>{item.itemDesc}</h4>
																	<h4>{item.package?.courier?.companyName}</h4>
																	<Button
																		text='Get label'
																		style='secondary'
																		size='md'
																		type='button'
																		onClick={(e) => {
																			e.preventDefault();
																			item.saveItemLabel();
																		}}
																	/>
																</div>
															);
														})}
													</div>
												</div>
											</div>
										</div>
									</>
								)}
							</>
						) : (
							<Loading type='circle' center />
						)}
					</FormStep>
				</>
			) : (
				<Loading type='circle' />
			)}
			<div className='pageEnd' ref={pageEnd}></div>
		</>
	);
}
